import * as React from "react"
import { Link } from "gatsby"
import Button from "../Button/Button"
// import { PostItemStyles } from "./PostStyles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FeaturedProductStyles } from "./FeaturesStyles"

const BlogItem = ({ node }, key) => {
  const { title, gatsbyPath, introduction, createdAt, headerImage } = node
  const image = getImage(headerImage)
  return (
    // <PostItemStyles key={key} to={gatsbyPath}>
    <FeaturedProductStyles key={key}>
      <Link to={gatsbyPath}>
        <GatsbyImage
          className="features__item--img"
          image={image}
          alt="Product Image"
        />
        {title && introduction && (
          <div className="features__item--content">
            {title && <h4>{title}</h4>}
            {introduction && <p>{introduction}</p>}
            <Button text="続きを読む" as="span" arrow={true} />
            <p>{createdAt}</p>
          </div>
        )}
      </Link>
    </FeaturedProductStyles>
    // </PostItemStyles>
  )
}

export default BlogItem
